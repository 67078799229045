//Always wait for the document to be load
$(document).ready(function () {
    /**
     * INIT
     */
    vueCrudFavList();
    vueLoginModal();
    vueShareModal();
    vueShareModalLists();
    vueMutePopover();
    vuePremiumModal();
    vueCartaDeRutes();

    /**
     * Vue Component
     */

    /**
     * Creates the vue premium-modal
     */
    function vueCartaDeRutes() {
        let id = "vue-carta-de-rutes";
        if (!document.getElementById(id)) {
            return;
        }

        new Vue({
            el: "#" + id,
            data: {
                isInFavoriteListsPage: !!INJECT.listId,
                favoriteListId: INJECT.listId,
                titleName: INJECT.titleName,
                isShowHint: INJECT.showHintCartaDeRutes,
                isUserPremium: INJECT.isUserPremium,
                userImage: INJECT.userImage,
                lang: INJECT.lang,
                i18n: INJECT.i18n,
            },
            template: `
			<carta-de-rutes
			  :isModalOpen="false"
			  :isInFavoriteListsPage="isInFavoriteListsPage"
			  :favoriteListId="favoriteListId"
			  :titleName="titleName"
			  :isShowHint="isShowHint"
			  :isUserPremium="isUserPremium"
			  :userImage="userImage"
			  :lang="lang"
			  :i18n="i18n"
			></carta-de-rutes>
		`,
        });
    }

    /**
     * Creates the vue premium-modal
     */
    function vuePremiumModal() {
        var elementId = "vue-premium-modal";
        if (!document.getElementById(elementId)) {
            return;
        }

        var selectedFeature = GLOBAL_PREMIUM_FEATURES.EXPLORE_USER;
        if (window.location.href.indexOf("event=listfavorites") > -1) {
            selectedFeature = GLOBAL_PREMIUM_FEATURES.CUSTOM_TRAIL_LISTS;
        }

        new Vue({
            el: "#" + elementId,
            data: {
                isPremiumModalOpen: false,
                selectedFeature: selectedFeature,
                isUserLoggedIn: INJECT.isUserLoggedIn,
                socialLoginState: INJECT.socialLoginState, // login-modal
                recaptchaModule: INJECT.recaptchaModule, // login-modal
                socialLoginModule: INJECT.socialLoginModule, // login-modal
                i18n: INJECT.i18n,
            },
            template: `
			<premium-modal
				:isModalOpen="isPremiumModalOpen"
				:isUserLoggedIn="isUserLoggedIn"
				:selectedFeature="selectedFeature"
				:socialLoginState="socialLoginState"
				:recaptchaModule="recaptchaModule"
				:socialLoginModule="socialLoginModule"
				:i18n="i18n"
                :analyticsRef="analyticsRef"
				@closePremiumModalEvent="closePremiumModal"
			  ></premium-modal>
        `,
            methods: {
                openPremiumModal: openPremiumModal,
                closePremiumModal: closePremiumModal,
            },
            computed: {
                analyticsRef: analyticsRef,
            },
            mounted: function () {
                this.$eventBus.$on("showPremiumModal", () => {
                    this.openPremiumModal();
                });
            },
            beforeDestroy: function () {
                this.$eventBus.$off("showPremiumModal");
            },
        });

        function analyticsRef() {
            return this.selectedFeature.description;
        }

        /**
         * -----------------
         * ---- METHODS ----
         * -----------------
         * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
         */
        function openPremiumModal() {
            this.isPremiumModalOpen = true;
        }

        function closePremiumModal() {
            this.isPremiumModalOpen = false;
        }
    }

    $(".search-button").click(function (event) {
        if (isOrg || isOwnProfile || userType === "USER_PREMIUM") {
            gtagEvent("goto_map_click", {
                ref: isOrg ? "profile_org" : "profile_user",
            });
            return true;
        } else {
            showPremiumModal();
            return false;
        }
    });
});

function showPremiumModal() {
    new Vue().$eventBus.$emit("showPremiumModal");
}

function showLoginModal() {
    new Vue().$eventBus.$emit("showLoginModal");
}

window.addEventListener("load", function (event) {
    var href = window.location.href;
    if (href.includes("#private_message")) {
        setTimeout(function () {
            //We need to queue the scroll into the js events or it will not be executed
            document.getElementById("private_message").scrollIntoView();
        }, 1);
    }
});

//URLSearchParams polyfill
function getParamFromURL(name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
        window.location.href
    );
    if (results == null) {
        return null;
    } else {
        return decodeURI(results[1]) || 0;
    }
}

// Function to replace the parameter in the src URL and refresh the iframe
function replaceSrcParam(param, newValue, isAttribute) {
    var embedCode = $("#embed-code");
    var embedCodeValue = embedCode.val();

    // If the param exists
    if (embedCodeValue.match(param)) {
        if (isAttribute) {
            // Change the value
            var regex = new RegExp("(" + param + ')="([0-9]+)"', "ig");
            var newEmbedCodeValue = embedCodeValue.replace(
                regex,
                '$1="' + newValue + '"'
            );
        } else {
            // Change the value
            var regex = new RegExp("(" + param + "=)[a-z]+", "ig");
            var newEmbedCodeValue = embedCodeValue.replace(
                regex,
                "$1" + newValue
            );
        }

        // Update the value
        embedCode.val(newEmbedCodeValue);

        // Refresh the preview
        $("#iframe-preview-container").animate(
            {
                opacity: 0,
            },
            100,
            function () {
                $("#iframe-preview-container").html(newEmbedCodeValue);
                $("#iframe-preview-container").delay(250).animate(
                    {
                        opacity: 1,
                    },
                    100
                );
            }
        );
    }
    return false;
}

/**
 * Used by VUE. This is a legacy part that we reuse
 * @type {Array|jQuery|HTMLElement}
 */
var $body = $("body");
$body.on("addChildrenListFavorites", function (event, ret) {
    if (ret && ret.id) {
        var privateBadge = "";
        if (!ret.isPublic) {
            privateBadge =
                '<span id="privacy" class="pull-right"><span class="icon icon-lock-closed color-icon-lock-private"></span></span>';
        }
        var html =
            '<a href="' +
            urlFavoriteList +
            "" +
            ret.id +
            '" class="list-group-item  favorite-list-item-format">' +
            '<div class="flex-align-middle pl">' +
            '<p style=" font-size: 14pt; line-height: 24pt; color: #4B8C2A">' +
            privateBadge +
            ret.name +
            "</p>" +
            '<p style="color: #555555;">' +
            "0 " +
            txtTrails +
            "</p>" +
            "</div>" +
            '<div class="plain-badge flex-align-middle pr"> <i class="glyphicon glyphicon-menu-right" style="font-size: 16pt; font-weight: 100; color: #AAAAAA;"></i></div>' +
            "</a>";
        $(".favorite-list-item-format").last().after(html);
        availableLists = --availableLists < 0 ? 0 : availableLists;
        favoriteCreateList.availableLists =
            availableLists + " " + translations.txtRemaining;
    }
});

/**
 * Used by VUE. This is a legacy part that we reuse
 */
$body.on("tooManyListFavorites", function (event, ret) {
    if (ret.err) {
        showErrorMessage(ret.err);
    }
});

function showErrorMessage(msg) {
    var $favoriteFeedbackError = $("#favorite-list-feedback-error");
    $favoriteFeedbackError.text(msg);
    $favoriteFeedbackError.show();
    setTimeout(function () {
        $favoriteFeedbackError.hide();
    }, 5000);
}

/** Email **/
function sendFavoriteLists(e) {
    //compose mailto
}

const debounce = function (fn, time) {
    let timeout;

    return function () {
        const functionCall = function () {
            fn.apply(this, arguments);
        };

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    };
};

//Mutex global variables for favorite "Remove from List and Add to List again"
var processingFavorite = false;
$body.on("click", ".fav-button", function (event) {
    if (!processingFavorite) {
        processingFavorite = true;
        var currentTarget = $(event.currentTarget);
        var trailId = currentTarget.attr("id");
        var listId = getParamFromURL("listId");
        var isOn = $(currentTarget).hasClass("added");
        sendRequestFavorite(
            trailId,
            listId,
            isOn,
            debounce(function () {
                if (!currentTarget.hasClass("added")) {
                    //Add to favorites
                    currentTarget.addClass("added");
                    var removeFromFavoritesText =
                        translations["txtRemoveFromFavorites"];
                    currentTarget.attr("title", removeFromFavoritesText);
                    currentTarget
                        .find(".icon-favorite")
                        .attr(
                            "src",
                            "https://sc.wklcdn.com/wikiloc/assets/styles/images/icons/add_list_on.svg"
                        )
                        .attr("title", removeFromFavoritesText)
                        .attr("alt", removeFromFavoritesText);
                    currentTarget
                        .find(".favourites-text")
                        .text(removeFromFavoritesText);

                    //Remove disabled style
                    var rootDiv = currentTarget.closest(".clearfix");
                    rootDiv.removeClass("disable-div");
                } else if (currentTarget.hasClass("added")) {
                    //Remove from favorites
                    currentTarget.removeClass("added");
                    var addToFavoritesText = translations["txtAddToFavorites"];
                    currentTarget.attr("title", addToFavoritesText);
                    currentTarget
                        .find(".icon-favorite")
                        .attr(
                            "src",
                            "https://sc.wklcdn.com/wikiloc/assets/styles/images/icons/add_list.svg"
                        )
                        .attr("title", addToFavoritesText)
                        .attr("alt", addToFavoritesText);
                    currentTarget
                        .find(".favourites-text")
                        .text(addToFavoritesText);

                    //Add disabled style
                    var rootDiv = currentTarget.closest(".clearfix");
                    rootDiv.addClass("disable-div");
                }
                processingFavorite = false;
            }, 200)
        );
    }
});

function sendRequestFavorite(trailId, listId, isOn, callback) {
    var request = $.ajax({
        url: "/wikiloc/favourite.do?",
        method: "post",
        dataType: "json",
        data: { sid: trailId, listId: listId, fav: !isOn },
    });
    request.done(function (ret) {
        if (ret.res === "OK") {
            callback(null, ret);
        } else if (ret.res === "KO") {
        }
    });
}

function vueCrudFavList() {
    var elementId = "vue-crud-favlist";
    if (!document.getElementById(elementId)) {
        return;
    }
    new Vue({
        el: "#" + elementId,
        data: {
            isModalOpen: false,
            remaining: availableLists,
            listId: INJECT.listId,
            name: INJECT.name,
            isPrivate: INJECT.isPrivate,
            i18n: INJECT.i18n,
            isUserPremium: INJECT.isUserPremium,
        },
        template: `
      <div id="container">     
        <!-- from favorites.jsp -->
        <li v-if="isEdit" class="topbar-fav-right-action-list-edit" v-on:click="onClickOpen">
          <span class="edit-list">
            <a href="#">
              <span class="glyphicon icon-pencil"></span>
              {{i18n.txtEditList}}
            </a>
          </span>
        </li>
        <!-- from listfavorites.jsp -->
        <a v-else href="#" class="list-group-item text-center new-list-favourite" v-on:click="onClickOpen">
          <div class="list-favourite-create-text">
            <img src="https://sc.wklcdn.com/wikiloc/assets/styles/images/01_plus.svg"/>
            {{i18n.txtNewList}}
          </div>
        </a>
        
        <crud-favlist
          :isModalOpen="isModalOpen"
          :remaining="remaining"
          :listId="listId"
          :originalName="name"
          :originalIsPrivate="isPrivate"
          :i18n="i18n"
          @sync-is-modal-open="syncIsModalOpen"
          @syncCreateList="syncCreateList"
          @syncEditList="syncEditList"
          @syncDeleteList="syncDeleteList"
        >
        </crud-favlist>
      </div>
  `,
        methods: {
            onClickOpen: onClickOpen,
            syncIsModalOpen: syncIsModalOpen,
            syncCreateList: syncCreateList,
            syncEditList: syncEditList,
            syncDeleteList: syncDeleteList,
        },
        computed: {
            isEdit: isEdit,
        },
        mounted: mounted,
    });

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function onClickOpen(e) {
        if (!this.isUserPremium) {
            showPremiumModal();
        } else if (this.remaining <= 0) {
            e.preventDefault();
            $("body").trigger("tooManyListFavorites", {
                err: this.i18n.txtReachedLimit,
            });
        } else {
            this.isModalOpen = !this.isModalOpen;
        }
    }

    /**
     * Local event handler with child crud-favlist component. No global eventbus
     * Keeps reactive the loginModal open status
     *
     * Sync modal to close it
     * @param payload with {}
     */
    function syncIsModalOpen(payload) {
        this.isModalOpen = false;
    }

    /**
     * Local event handler with child crud-favlist component. No global eventbus
     * Add new list to the profile favorite list
     *
     * @param payload CreateListResponse {id, name, count, isPrivate}
     */
    function syncCreateList(payload) {
        let ret = {
            id: payload.id,
            name: payload.name,
            isPublic: !payload.isPrivate,
        };

        $("body").trigger("addChildrenListFavorites", ret);

        this.remaining--;
    }

    /**
     * Local event handler with child crud-favlist component. No global eventbus
     * Sync favouriteList info from the edit modal
     *
     * @param payload UpdateListResponse {id, name, isPrivate}
     */
    function syncEditList(payload) {
        var name = payload.name;
        var isPublic = !payload.isPrivate;
        $("#list-name").html(name);
        if (isPublic) {
            $("#privacy").empty();
            $(".topbar-fav-right-action-list-share").show();
        } else {
            $("#privacy").html(
                '<span class="icon icon-lock-closed color-icon-lock-private">'
            );
            $(".topbar-fav-right-action-list-share").hide();
        }
    }

    /**
     * Local event handler with child crud-favlist component.
     * No global eventbusk.
     * keeps reactive the loginModal open status
     *
     * Remove the favorite list and go to the list page
     *
     * @param payload {}
     */
    function syncDeleteList(payload) {
        window.location.replace(goToProfileFavorites);
        this.remaining++;
    }

    /**
     * ---- COMPUTED ATTRIBUTES ----
     * https://vuejs.org/v2/guide/computed.html#Computed-Properties
     */
    function isEdit() {
        return this.listId != null;
    }

    /**
     * -----------------------
     * ---- VUE LIFECYCLE ----
     * -----------------------
     * https://vuejs.org/v2/guide/instance.html#Lifecycle-Diagram
     */
    function mounted() {}
}

/**
 * Creates the vue login-modal
 */
function vueLoginModal() {
    var elementId = "vue-login-modal";
    if (!document.getElementById(elementId)) {
        return;
    }

    new Vue({
        el: "#" + elementId,
        data: {
            isLoginModalOpen: false,
            socialLoginState: INJECT.socialLoginState,
            recaptchaModule: INJECT.recaptchaModule,
            socialLoginModule: INJECT.socialLoginModule,
            i18n: INJECT.i18n,
        },
        template: `
      <login-modal 
        :isModalOpen="isLoginModalOpen" 
        :state="socialLoginState"
        :recaptchaModule="recaptchaModule"
        :socialLoginModule="socialLoginModule"
        :i18n="i18n"
        @closeLoginModalEvent="closeLoginModal"
      >
      </login-modal>
        `,
        methods: {
            openLoginModal: openLoginModal,
            closeLoginModal: closeLoginModal,
        },
        computed: {},
        mounted: function () {
            this.$eventBus.$on("showLoginModal", () => {
                this.openLoginModal();
            });
        },
        beforeDestroy: function () {
            this.$eventBus.$off("showLoginModal");
        },
    });

    /**
     * -----------------
     * ---- METHODS ----
     * -----------------
     * https://vuejs.org/v2/guide/instance.html#Data-and-Methods
     */
    function openLoginModal() {
        this.isLoginModalOpen = true;
    }

    function closeLoginModal() {
        this.isLoginModalOpen = false;
    }
}

function vueShareModal() {
    //Share modal show
    $("#share-modal").on("click", function (e) {
        e.preventDefault();
        new Vue().$eventBus.$emit("showShareModal", { target: e.target });

        if (!this.classList.contains("share-active")) {
            this.classList.add("share-active");
        }

        return true;
    });

    var elementId = "vue-share-modal";
    if (!document.getElementById(elementId)) {
        return;
    }
    new Vue({
        el: "#" + elementId,
        data: {
            isModalOpen: false,
            isUserLoggedIn: INJECT.isUserLoggedIn,
            socialLoginState: INJECT.socialLoginState,
            recaptchaModule: INJECT.recaptchaModule,
            socialLoginModule: INJECT.socialLoginModule,
            permalink: INJECT.permalink,
            title: INJECT.title,
            i18n: INJECT.i18n,
            target: "",
            shareEnabled: INJECT.shareEnabled,
            analytics: {
                content_type: "user",
                item_id: INJECT.userId,
                ref: "user_profile",
            },
        },
        template: `
      <share-modal
          :isModalOpen="isModalOpen"
          :isUserLoggedIn="isUserLoggedIn"
          :socialLoginState="socialLoginState"
          :recaptchaModule="recaptchaModule"
          :socialLoginModule="socialLoginModule"
          :permalink="permalink"
          :title="title"
          :i18n="i18n"
          :target="target"
          :shareEnabled="shareEnabled"
          :analytics="analytics"
          @closeShareModalEvent="closeShareModal">

      </share-modal>
      `,
        methods: {
            openShareModal: openShareModal,
            closeShareModal: closeShareModal,
        },
        mounted: function () {
            this.$eventBus.$on("showShareModal", (payload) => {
                if (this.isModalOpen) {
                    this.closeShareModal();
                } else {
                    this.openShareModal(payload.target);
                }
            });
        },
    });

    function openShareModal(target) {
        this.isModalOpen = true;
        this.target = target;
    }

    function closeShareModal() {
        this.isModalOpen = false;

        if (
            document
                .getElementById("share-modal")
                .classList.contains("share-active")
        ) {
            document
                .getElementById("share-modal")
                .classList.remove("share-active");
        }
    }
}

function vueShareModalLists() {
    $("#share-modal-lists").on("click", function (e) {
        e.preventDefault();
        new Vue().$eventBus.$emit("showShareModalFavoriteLists", {
            target: e.target,
        });
        return true;
    });

    var elementId = "vue-share-modal-lists";
    if (!document.getElementById(elementId)) {
        return;
    }
    var data = {
        isModalOpen: false,
        isUserLoggedIn: INJECT.isUserLoggedIn,
        socialLoginState: INJECT.socialLoginState,
        recaptchaModule: INJECT.recaptchaModule,
        socialLoginModule: INJECT.socialLoginModule,
        permalink: INJECT.permalink,
        title: INJECT.titleList,
        i18n: INJECT.i18n,
        target: "",
        shareEnabled: {
            email: {
                emailDescriptionComputed: INJECT.emailDescriptionComputed,
            },
            twitter: true,
            facebook: true,
            whatsapp: true,
            qr: {
                url: INJECT.embedQrURL,
                printQr: INJECT.printQr,
                type: "FAVORITE_LIST",
            },
        },
        analytics: {
            content_type: "trail_list",
            item_id: INJECT.listId,
            ref: "trail_list",
        },
    };

    if (typeof INJECT.enableQR === "boolean" && !INJECT.enableQR) {
        delete data.shareEnabled.qr;
    }

    new Vue({
        el: "#" + elementId,
        data: data,
        template: `
    <share-modal
        :isModalOpen="isModalOpen"
        :isUserLoggedIn="isUserLoggedIn"
        :socialLoginState="socialLoginState"
        :recaptchaModule="recaptchaModule"
        :socialLoginModule="socialLoginModule"
        :permalink="permalink"
        :title="title"
        :i18n="i18n"
        :target="target"
        :shareEnabled="shareEnabled"
        :analytics="analytics"
        @closeShareModalEvent="closeShareModal">

    </share-modal>
    `,
        methods: {
            openShareModal: openShareModal,
            closeShareModal: closeShareModal,
        },
        mounted: function () {
            this.$eventBus.$on("showShareModalFavoriteLists", (payload) => {
                if (this.isModalOpen) {
                    this.closeShareModal();
                } else {
                    this.openShareModal(payload.target);
                }
            });
            this.$eventBus.$on("crud-favlist:name", (data) => {
                INJECT.printQr.printTitle = data.name;
            });
        },
    });

    function openShareModal(target) {
        this.isModalOpen = true;
        this.target = target;
    }

    function closeShareModal() {
        this.isModalOpen = false;
    }
}

function vueMutePopover() {
    //Mute popover show
    $("#mute-popover").on("click", function (e) {
        e.preventDefault();
        new Vue().$eventBus.$emit("showMutePopover", { target: e.target });

        var threeDotsButton = document.getElementById("three-dots-button");
        if (!threeDotsButton.classList.contains("three-dots-button-hover")) {
            threeDotsButton.classList.add("three-dots-button-hover");
        }

        return true;
    });

    //Mute tag onClick event
    $("#muted-tag-popover").on("click", function (e) {
        e.preventDefault();
        new Vue().$eventBus.$emit("showMutePopover", { target: e.target });

        var mutedTag = document.getElementById("muted-tag-popover");
        if (mutedTag.classList.contains("muted-tag")) {
            mutedTag.classList.remove("muted-tag");
            mutedTag.classList.add("muted-tag-hover");
        }

        return true;
    });

    var mutedTagPopover = "muted-tag-popover";
    if (!document.getElementById(mutedTagPopover)) {
        return;
    } else if (INJECT.isMuted) {
        document.getElementById(mutedTagPopover).style.visibility = "visible";
    }

    var elementId = "vue-mute-popover";
    if (!document.getElementById(elementId)) {
        return;
    }
    new Vue({
        el: "#" + elementId,
        data: {
            userName: INJECT.userName,
            userId: INJECT.userId,
            isMuted: INJECT.isMuted,
            isPopoverOpen: false,
            i18n: INJECT.i18n,
            target: "",
        },
        template: `
      <mute-popover
          :userName="userName"
          :userId="userId"
          :isMuted="isMuted"
          :isPopoverOpen="isPopoverOpen"
          :i18n="i18n"
          :target="target"
          @closeMutePopoverEvent="closeMutePopover">
      </mute-popover>
      `,
        methods: {
            openMutePopover: openMutePopover,
            closeMutePopover: closeMutePopover,
        },
        mounted: function () {
            this.$eventBus.$on("showMutePopover", (payload) => {
                if (this.isPopoverOpen) {
                    this.closeMutePopover();
                } else {
                    this.openMutePopover(payload.target);
                }
            });

            this.$eventBus.$on("mutePopoverIsMuted", (payload) => {
                this.isMuted = payload.isMuted;
                if (payload.isMuted) {
                    document.getElementById(mutedTagPopover).style.visibility =
                        "visible";
                } else {
                    document.getElementById(mutedTagPopover).style.visibility =
                        "hidden";
                    this.$destroy();
                    this.$el.parentNode.removeChild(this.$el);
                    var mutePopover = document.getElementById("mute-popover");
                    mutePopover.parentNode.removeChild(mutePopover);
                }
            });
        },
    });

    function openMutePopover(target) {
        this.isPopoverOpen = true;
        this.target = target;
    }

    function closeMutePopover() {
        this.isPopoverOpen = false;

        var mutedTag = document.getElementById("muted-tag-popover");
        if (
            mutedTag != null &&
            mutedTag.classList.contains("muted-tag-hover")
        ) {
            mutedTag.classList.remove("muted-tag-hover");
            mutedTag.classList.add("muted-tag");
        }

        var threeDotsButton = document.getElementById("three-dots-button");
        if (
            threeDotsButton != null &&
            threeDotsButton.classList.contains("three-dots-button-hover")
        ) {
            threeDotsButton.classList.remove("three-dots-button-hover");
        }
    }
}
